import { GET_CONDUCTOR } from "../actions/conductor.action";

const initialState = {}

export default function conductorReducer(state = initialState, action) {

    switch (action.type) {
        case GET_CONDUCTOR:
            return action.payload
        default:
            return state;
    }
}