import '../../assets/css/PopupImg.css'
import UserDetail from '../Users/UserDetail'
import { useState, useEffect } from 'react';

const PopupImg = ({ img, setIsOpenImage }) => {
    const [rotation, setRotation] = useState(0);

    const handleImageRedo = () => {
        setRotation(rotation + 90);
    };
    const handleImageUndo = () => {
        setRotation(rotation - 90);
    };

    return (
        <div className='PopupBackground'>
            <div className="container-popup_img">
                <div className="popup_header_img">
                    <span className="material-icons-sharp" onClick={() => setIsOpenImage(false)}>close</span>
                </div>
                <div className="divImg">
                    <div className="rotation">
                        <span className="material-icons-sharp" onClick={handleImageUndo}>undo</span>
                        <span className="material-icons-sharp" onClick={handleImageRedo}>redo</span>
                    </div>
                    <img src={img} alt="img"
                        style={{ transform: `rotate(${rotation}deg)` }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PopupImg
