import axios from 'axios';
import { api_route } from '../../../pages/utils/api_routes';
import { useState } from 'react';
import { showToastError, showToastSuccèss } from '../../../pages/utils/toast';

export const GET_PROJET_VERSION = "GET_TRAJET_VERSION";
export const UPDATE_PROJET_VERSION = "UPDATE_TRAJET_VERSION";



export const getProjetVersion = (id=1) => {
    return async (dispatch) => {
        return await axios.get(api_route?.projet.url + 'getProjetVersion/' + id)
            .then((res) => {
                dispatch({ type: GET_PROJET_VERSION, payload: res.data })
            });
    }
}


export const updateProjeProjetVersion = (id=1, version) => {
    return async (dispatch) => {
        return await axios.put(api_route?.projet.url + 'setProjetVersion/' + id + '/' + version)
            .then((res) => {
                dispatch({ type: UPDATE_PROJET_VERSION, payload: { id, version } })
                showToastSuccèss("version modifié avec succèss")
            })
            .catch(err => showToastError("Erreur de modification de la version ", err))
    }
}
