import '../../assets/css/UserDetail.css'
// import Button from '../components/Button'
import userProfile from '../../assets/img/user.jpg'
// import Toggle from '../components/Toggle'
import React, { useState } from 'react';
import axios from 'axios';
import { api_route } from '../utils/api_routes';
import PopupImg from '../components/PopupImg';
import { useDispatch } from 'react-redux';
import { deleteUser, putUser } from '../../store/actions/user.action';
import ToggleButton from '../components/Toggle';
import PopupConfirm from '../components/PopupConfirm';
import { ToastContainer } from 'react-toastify';

const UserDetail = ({ itemSelected, setIsOpenDetail }) => {
    let userSelected = itemSelected
    const [isChecked, setIsChecked] = useState(userSelected.certified);
    const [isBlocked, setIsBlocked] = useState(userSelected.blocked);
    const [isOpenImage, setIsOpenImage] = useState(false);
    const [isHandleDeleteOpen, setIsHandleDeleteOpen] = useState(false);
    const dispatch = useDispatch();

    const toggleCheckCertified = () => {
        dispatch(putUser('userCertified', userSelected.id, !isChecked))
        setIsChecked(!isChecked)
    };

    const toggleCheckBloked = () => {
        dispatch(putUser('userBlocked', userSelected.id, !isBlocked))
        setIsBlocked(!isBlocked)
    };

    
    const confirmDelete = (val) => {
        if (val) {
            dispatch(deleteUser(userSelected.id))
                .then((res) => {
                    setIsHandleDeleteOpen(false)
                    setIsOpenDetail(false)
                })
        } else {
            setIsHandleDeleteOpen(false)
        }
    }

    return (
        <>
            <div className="container-user" >
                <ToastContainer />
                <div className="popup_header d-f-c">
                    <div className="btn-header d-f-c">
                        <button className='btn-delete d-f-c' onClick={() => setIsHandleDeleteOpen(true)}><span className="material-icons-sharp delete">delete</span></button>
                    </div>
                    <h1 className='titlee'>Information sur l'utilisateur</h1>
                    <span className="material-icons-sharp" onClick={() => setIsOpenDetail(false)}>close</span>
                </div>
                <div className="profile_setting__bloc">
                    <div className="profile_setting__blocLeft">
                        <div className="profile_setting__image">
                            <img style={{ borderRadius: "50%" }} src={userSelected.profilePicture ? userSelected.profilePicture : userProfile} alt="" />
                            <h1>{userSelected.name}</h1>
                            <br />
                            <div className="profile_setting__permis">
                                {userSelected.driverLicensePicture ?
                                    <img src={userSelected.driverLicensePicture} alt="" onClick={() => setIsOpenImage(true)} />
                                    :
                                    <div className='permis'>Pas de permiss</div>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="profile_setting__bloc_righ">
                        <div className="profile_setting">
                            <h1>Détails du profils</h1>
                            <div className="row">
                                <div className="label">Id</div>
                                <div className="value">{userSelected.id}</div>
                            </div>
                            <div className="row">
                                <div className="label">Nom</div>
                                <div className="value">{userSelected.name}</div>
                            </div>
                            <div className="row">
                                <div className="label">Email</div>
                                <div className="value">{userSelected.email}</div>
                            </div>
                            <div className="row">
                                <div className="label">Téléphone</div>
                                <div className="value">{userSelected.phone || "-"}</div>
                            </div>
                            <div className="row">
                                <div className="label">Ville</div>
                                <div className="value">{userSelected.city || "-"}</div>
                            </div>
                            <div className="row">
                                <div className="label">Adresse</div>
                                <div className="value">{userSelected.completeAdresse || "-"}</div>
                            </div>
                            <div className="row">
                                <div className="label">Code postal</div>
                                <div className="value">{userSelected.postalCode || "-"}</div>
                            </div>
                            <div className="row">
                                <div className="label">Date d'enregistrement</div>
                                <div className="value">{userSelected.registrationDate?.split('T')[0] + ' à ' + userSelected.registrationDate?.split('T')[1]}</div>
                            </div>
                            <div className="row">
                                <div className="label">Certifié </div>
                                <ToggleButton
                                    handleClick={toggleCheckCertified}
                                    isToggled={isChecked}
                                />
                                {/* <div className="value">
                                    <button onClick={toggleCheckCertified} style={{
                                        backgroundColor: isChecked ? 'green' : 'red',
                                        color: 'white',
                                        padding: '6px 26px',
                                        border: 'none',
                                        borderRadius: '5px',
                                        minWidth: '76px',
                                        cursor: 'pointer'
                                    }}>
                                        {isChecked ? 'Oui' : 'Non'}
                                    </button><span>cliquez ici pour modifier</span> </div> */}
                            </div>
                            <div className="row">
                                <div className="label">Désactiver le compte </div>
                                <ToggleButton
                                    handleClick={toggleCheckBloked}
                                    isToggled={isBlocked}
                                />
                                {/* <div className="value">
                                    <button onClick={toggleCheckBloked} style={{
                                        backgroundColor: isBlocked ? 'green' : 'red',
                                        color: 'white',
                                        padding: '6px 26px',
                                        border: 'none',
                                        borderRadius: '5px',
                                        minWidth: '76px',
                                        cursor: 'pointer'
                                    }}>
                                        {isBlocked ? 'Oui' : 'Non'}
                                    </button><span>cliquez ici pour modifier</span> </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpenImage ?
                <PopupImg img={userSelected.driverLicensePicture} setIsOpenImage={setIsOpenImage} />
                :
                <></>
            }
             {
                isHandleDeleteOpen &&
                <PopupConfirm
                    title='Vous êtes sur le point de supprimer définitivement cette utilisateur ?'
                    action={confirmDelete}
                />
            }
        </>

    )
}

export default UserDetail