import { GET_TRAJET } from "../actions/trajet.action";
import { DELETE_TRAJET } from "../actions/trajet.action";
import { UPDATE_TRAJET } from "../actions/trajet.action";

const initialState = []

export default function trajetReducer(state = initialState, action) {

    switch (action.type) {
        case GET_TRAJET:
            return action.payload
        case UPDATE_TRAJET:
            return state.map((trajet) => {
                if (trajet.id === action.payload.id) {
                    return {
                        ...trajet,
                        ...action.payload.trajet
                    };
                }
                return trajet;
            })
        case DELETE_TRAJET:
            return state.filter((trajet) => trajet.id !== action.payload)
        default:
            return state;
    }
}