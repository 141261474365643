import axios from 'axios';
import { api_route } from '../../pages/utils/api_routes';
import { showToastError, showToastSuccèss } from '../../pages/utils/toast';

export const GET_RESERVATION = "GET_RESERVATION";
export const UPDATE_RESERVATION = "UPDATE_RESERVATION";
export const DELETE_RESERVATION = "DELETE_RESERVATION";

export const getReservation = () => {
    return async (dispatch) => {
        return await axios.get(api_route?.reservation.url + 'reservation')
            .then((res) => {
                dispatch({ type: GET_RESERVATION, payload: res.data })
            });
    }
}

export const deleteReservation = (id) => {
    return async (dispatch) => {
        return await axios.delete(api_route?.reservation.url + 'reservation/' + id)
            .then((res) => {
                dispatch({ type: DELETE_RESERVATION, payload: id })
                showToastSuccèss('Cette reservation à été supprimé avec succès')
            })
            .catch(err => showToastError("Erreur de suppression de cette reservation"))
    }
}

export const updateReservation = (id, reservation) => {
    console.log('Affiche : ', reservation);
    return async (dispatch) => {
        return await axios.put(api_route?.reservation.url + 'reservation/' + id , reservation)
            .then((res) => {
                dispatch({ type: UPDATE_RESERVATION, payload: { id, reservation } })
                showToastSuccèss('Cette reservation a été modifié avec succès')
            })
            .catch(err => showToastError("Erreur de modification de cette reservation"))
    }
}