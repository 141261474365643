import '../../assets/css/Table.css'
import userIcon from '../../assets/img/user.jpg'
import { useState } from 'react';
import Popup from '../components/Popup';
import { useLocation } from 'react-router-dom';
import TrajetDetail from './TrajetDetail';
import { isEmpty } from '../utils/isEmpty';
import { useSelector } from 'react-redux';


const Table = ({titleTable, trajet }) => {

    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [trajetSelected, setTrajetSelected] = useState({})
    const location = useLocation();

    const goToDetailTrajet = (id) => {
        let traj = trajet.filter(elt => elt.id === id)[0];
        setIsOpenDetail(true);
        setTrajetSelected(traj);
        // navigate('userDetail')
    }

    // console.log(location.pathname);

    return (
        <>
            <div className="recent-oders">
                <h2>{titleTable} ({trajet?.length})</h2>
                <div className="container-table"
                    style={location.pathname === '/' ? { paddingBottom: '0px', height: '54vh' } : {}}
                >
                    <table>
                        <thead>
                            <tr>
                                <th>Ville de départ</th>
                                <th>Ville d'arrivé</th>
                                <th>Prix</th>
                                <th>Place disponible</th>
                                <th className='td_display_none'>Place reservées</th>
                                <th className='td_display_none'>Date et heure</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isEmpty(trajet) && trajet.sort((a, b) => b.id - a.id).map((elt, index) =>
                                <tr key={index} onClick={() => goToDetailTrajet(elt.id)}>
                                    <td>{elt.departurePoint}</td>
                                    <td>{elt.arrivalPoint}</td>
                                    <td>{elt.price}$</td>
                                    <td>{elt.seatAvailable}</td>
                                    <td className='td_display_none'>{elt.seatReserved}</td>
                                    <td className='td_display_none'>{elt.departureDate.split('T')[0]} à {elt.departureDate.split('T')[1]}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
                <a href="#">Voir tout </a>

            </div >

            {isOpenDetail &&
                <Popup itemSelected={trajetSelected}
                    setIsOpenDetail={setIsOpenDetail}
                    Compnent={TrajetDetail}
                /> }
        </>
    )
}

export default Table
