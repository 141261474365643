import { GET_TRAJET_PRICE, UPDATE_TRAJET_PRICE } from "../../actions/projet/projetReservPrice.action";

const initialState = []

export default function projetReservaPriceReducer(state = initialState, action) {

    switch (action.type) {
        case GET_TRAJET_PRICE:
            return action.payload
        case UPDATE_TRAJET_PRICE:
                    return {
                      ...state,
                      ...action.payload.price
                    }
        default:
            return state;
    }
}