import { GET_USERS, DELETE_USERS,PUT_USERS_Blocked, PUT_USERS_Certif } from "../actions/user.action";

const initialState = []

export default function userReducer(state = initialState, action) {

    switch (action.type) {
        case GET_USERS:
            return action.payload
        case PUT_USERS_Certif:
            return state.map((user) => {
                if (user.id === action.payload.idUser) {
                    return {
                        ...user,
                        certified: action.payload.value
                    };
                } else return user
            })
        case PUT_USERS_Blocked:
            return state.map((user) => {
                if (user.id === action.payload.idUser) {
                    return {
                        ...user,
                        blocked: action.payload.value
                    };
                } else return user
            })
        case DELETE_USERS:
            return state.filter((user) => user.id !== action.payload)
        default:
            return state;
    }
}