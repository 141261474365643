import { DELETE_ANNONCE, GET_ANNONCE, UPDATE_ANNONCE } from "../actions/annonce.action";

const initialState = []

export default function annonceReducer(state = initialState, action) {

    switch (action.type) {
        case GET_ANNONCE:
            return action.payload
        case UPDATE_ANNONCE:
            return state.map((annonce) => {
                if (annonce.id === action.payload.id) {
                    return {
                        ...annonce,
                        ...action.payload.annonce
                    };
                } else {
                    return annonce;
                }
            })
        case DELETE_ANNONCE:
            return state.filter((annonce) => annonce.id !== action.payload)
        default:
            return state;
    }
}