import { POST_EMAIL } from "../../actions/notification/mail.action";

const initialState = []

export default function sendMailReducer(state = initialState, action) {

    switch (action.type) {
        case POST_EMAIL:
            return [...state, action.payload]
        default:
            return state;
    }
}