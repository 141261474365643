import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import annonceReducer from "./annonce.reducer";
import trajetReducer from "./trajet.reducer";
import reservationReducer from "./reservation.reducer";
import conductorReducer from "./conductot.reducer";
import projetReducer from "./projet/projet.reducer";
import projetVersionReducer from "./projet/projetVersion.reducer";
import projetReservaPriceReducer from "./projet/projetResevaPrice.reducer";
import sendMailReducer from "./notification/mail.reducer";

export default combineReducers({
    userReducer,
    annonceReducer,
    trajetReducer,
    reservationReducer,
    conductorReducer,
    projetReducer,
    projetVersionReducer,
    projetReservaPriceReducer,
    sendMailReducer
})