import { GET_PROJET_VERSION, UPDATE_PROJET_VERSION } from "../../actions/projet/projetVersion.action";

const initialState = []

export default function projetVersionReducer(state = initialState, action) {

    switch (action.type) {
        case GET_PROJET_VERSION:
            return action.payload
        case UPDATE_PROJET_VERSION:
                    return {
                      ...state,
                      ...action.payload.version
                    }
              
        default:
            return state;
    }
}