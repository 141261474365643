import '../../assets/css/AnnonceDetail.css'
import React, { useState, useEffect } from 'react';
import { doPost } from '../utils/services';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { showToastWarning } from '../utils/toast';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { setObjetImbrique } from '../utils/functions';
import { deleteAnnonce, updateAnnonce } from '../../store/actions/annonce.action';
import PopupConfirm from '../components/PopupConfirm';
import { isEmpty } from '../utils/isEmpty';

const AnnonceDetail = ({ itemSelected, setIsOpenDetail }) => {

    const annonceInit = useSelector((state) => state.annonceReducer)
    let annonceSelected = !isEmpty(annonceInit) && annonceInit.filter((elt => elt.id === itemSelected.id))[0]
    const [formData, setFormData] = useState(annonceSelected);

    const [isUpdate, setIsUpdate] = useState(false)
    const [isFloating, setIsFloating] = useState(false);
    const [isHandleDeleteOpen, setIsHandleDeleteOpen] = useState(false);
    const users = useSelector((state) => state.userReducer)
    const dispatch = useDispatch();


    const handleChange = (e) => {
      if (e.target.name === 'users.id') {
            setFormData(setObjetImbrique(formData, 'users.id', e.target.value));
        } else if (e.target.name === 'departureDate') {
            let hour = String(new Date().getHours()).length === 1 ? 0 + '' + new Date().getHours() : new Date().getHours()
            let min = String(new Date().getMinutes()).length === 1 ? 0 + '' + new Date().getMinutes() : new Date().getMinutes()
            let sec = String(new Date().getSeconds()).length === 1 ? 0 + '' + new Date().getSeconds() : new Date().getSeconds()
            setFormData({ ...formData, [e.target.name]: e.target.value + 'T' + hour + ':' + min + ':' + sec });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
        console.log('formData : ', formData);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (annonceSelected === formData) {
            showToastWarning("Veillez modifier au moins un champ avant d'enregistrer")
        } else {
            let data = {
                "departureDate": formData.departureDate,
                "departurePoint": formData.departurePoint,
                "arrivalPoint": formData.arrivalPoint,
                "seatReserved": +formData.seatReserved,
                "userId": +formData.users.id,
            }
            console.log('formData : ', data);
            dispatch(updateAnnonce(annonceSelected.id, data))
                .then((res) => {
                    setIsUpdate(false)
                })
                .catch((err) => { console.log('*Erreur de la modification : ', err); })

        }
    }


    const confirmDelete = (val) => {
        if (val) {
            dispatch(deleteAnnonce(annonceSelected.id))
                .then((res) => {
                    setIsHandleDeleteOpen(false)
                    setIsOpenDetail(false)
                })
                .catch((err) => console.log('ERREUR de suppression du trajet : ', err))
        } else {
            setIsHandleDeleteOpen(false)
        }
    }

 

    return (
        <>
            <div className="container-user" >
                <ToastContainer />
                <div className="popup_header d-f-c">
                    <div className="btn-header d-f-c">
                        <button className='btn-edit d-f-c' onClick={() => setIsUpdate(true)}><span className="material-icons-sharp edit">edit</span></button>
                        <button className='btn-delete d-f-c' onClick={() => setIsHandleDeleteOpen(true)}><span className="material-icons-sharp delete">delete</span></button>
                    </div>
                    <h1 className='titlee'>Information sur l'annonce</h1>
                    <span className="material-icons-sharp" onClick={() => setIsOpenDetail(false)}>close</span>
                </div>
                {isUpdate && <form onSubmit={handleSubmit}>
                    <div className="div-form">
                        <div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="id">Id</label>
                                <input className='' type="text" disabled value={formData.id} name="id" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''}>Adresse de départ</label><br />
                                <input name="departurePoint" type="text" value={formData.departurePoint} onChange={handleChange}/>
                            </div><br />
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''}>Adresse d'arrivé</label><br />
                                <input name="arrivalPoint"  type="text" value={formData.arrivalPoint} onChange={handleChange}/>
                            </div><br />


                        </div>
                        <div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="departureDate">Date</label>
                                <input type="date" value={formData.departureDate.split('T')[0]} name="departureDate" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="seatReserved">Place reservé</label>
                                <input type="number" min={1} value={formData.seatReserved} name="seatReserved" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="users.id">Passager</label><br />
                                <select name="users.id" onChange={handleChange}>
                                    <option value={formData.users.id}>{formData.users.name}</option>
                                    {
                                        users?.map((item, index) => (
                                            <option key={index} name="users.id" value={item.id}> {item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className='div-btn-submit d-f-c'>
                        <button className="btn btn-annuler" onClick={() => setIsUpdate(false)} >Annuler</button>
                        <button className="btn" type="submit" >Enregistrer</button>
                    </div>
                </form >
                }
                <div className="profile_setting__bloc">
                    <div className="profile_setting__blocLeft">
                        <div className="profile_setting__image">
                            <h1>{annonceSelected.departurePoint}</h1>
                            <span className="material-icons-sharp east">east</span>
                            <h1>{annonceSelected.arrivalPoint}</h1>
                        </div>
                    </div>
                    <div className="profile_setting__bloc_righ">
                        <div className="profile_setting">
                            <h1>Détails de l'annonce</h1>
                            <div className="row">
                                <div className="label">Id</div>
                                <div className="value">{annonceSelected.id}</div>
                            </div>
                            <div className="row">
                                <div className="label">Ville de départ</div>
                                <div className="value">{annonceSelected.departurePoint}</div>
                            </div>
                            <div className="row">
                                <div className="label">Ville d'arrivé</div>
                                <div className="value">{annonceSelected.arrivalPoint}</div>
                            </div>
                            <div className="row">
                                <div className="label">Place reservées</div>
                                <div className="value">{annonceSelected.seatReserved}</div>
                            </div>
                            <div className="row">
                                <div className="label">Date et heure</div>
                                <div className="value">{annonceSelected.departureDate?.split('T')[0] + ' à ' + annonceSelected.departureDate?.split('T')[1]}</div>
                            </div>
                            <div className="row">
                                <div className="label">Passager</div>
                                <div className="value">{annonceSelected.users?.name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isHandleDeleteOpen &&
                <PopupConfirm
                    title='Vous êtes sur le point de supprimer définitivement cette annonce ?'
                    action={confirmDelete}
                />
            }
        </>

    )
}

export default AnnonceDetail 