import axios from 'axios';
import { api_route } from '../../pages/utils/api_routes';
import { useState } from 'react';
import { showToastError, showToastSuccèss } from '../../pages/utils/toast';

export const GET_TRAJET = "GET_TRAJET";
export const DELETE_TRAJET = "DELETE_TRAJET";
export const UPDATE_TRAJET = "UPDATE_TRAJET";

export const getTrajet = () => {
    return async (dispatch) => {
        return await axios.get(api_route?.trajet.url + 'itinerary')
            .then((res) => {
                dispatch({ type: GET_TRAJET, payload: res.data })
            });
    }
}

export const deleteTrajet = (id) => {
    return async (dispatch) => {
        return await axios.delete(api_route?.trajet.url + 'itinerary/' + id)
            .then((res) => {
                dispatch({ type: DELETE_TRAJET, payload: id })
                showToastSuccèss("Trajet supprilé avec succèss")
            })
            .catch(err => showToastError("Erreur de suppression de ce trajet"))
    }
}

export const updateTrajet = (id, trajet) => {
    console.log('Affiche : ', trajet);
    return async (dispatch) => {
        return await axios.put(api_route?.trajet.url + 'itinerary/' + id, trajet)
            .then((res) => {
                dispatch({ type: UPDATE_TRAJET, payload: { id, trajet } })
                showToastSuccèss("Trajet modifié avec succèss")
            })
            .catch(err => showToastError("Erreur de modification de ce trajet"))
    }
}
