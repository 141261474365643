import axios from 'axios';
import { api_route } from '../../../pages/utils/api_routes';

export const POST_EMAIL = "POST_EMAIL";
// export const PUT_USERS_Certif = "PUT_USERS_Certif";
// export const PUT_USERS_Blocked = "PUT_USERS_Blocked";

export const sendEmail = (data) => {
    return async (dispatch) => {
        return await axios.post(api_route.notification.url + 'sendEmail',data)
            .then((res) => {
                dispatch({ type: POST_EMAIL, payload: data })
                console.log(res);
            });
    }
}
 