import { useNavigate } from 'react-router-dom';
import '../../assets/css/Auth.css'
import { useState } from 'react';


const Auth = () => {
    const [sing_up_mode, setSing_up_mode] = useState("")
    const navigate = useNavigate();

    const goTo = (route) => {
        navigate(route)
    }
    const sign_up_btn = () => {
        setSing_up_mode("sign-up-mode")
    }
    const sign_in_btn = () => {
        setSing_up_mode("")
    }


    return (

        <div className={sing_up_mode + " container-auth"} >
            <div className="forms-container">
                <div className="signin-signup">
                    <form action="#" className="sign-in-form">
                        <h2 className="title">Connexion</h2>
                        <div className="input-field">
                            <i className="fas fa-user"></i>
                            <input type="text" placeholder="Username" />
                        </div>
                        <div className="input-field">
                            <i className="fas fa-lock"></i>
                            <input type="password" placeholder="Password" />
                        </div>
                        <input value="Connexion" className="btn solid" onClick={goTo('/')} />
                    </form>

                    {/* ===============================================================================  */}

                    <form action="#" className="sign-up-form">
                        <h2 className="title">Inscription</h2>
                        <div className="input-field">
                            <i className="fas fa-user"></i>
                            <input type="text" placeholder="Username" />
                        </div>
                        <div className="input-field">
                            <i className="fas fa-envelope"></i>
                            <input type="email" placeholder="Email" />
                        </div>
                        <div className="input-field">
                            <i className="fas fa-lock"></i>
                            <input type="password" placeholder="Password" />
                        </div>
                        <input className="btn" value="Inscription" onClick={goTo('/')} />
                    </form>
                </div>
            </div>

            <div className="panels-container">
                <div className="panel left-panel">
                    <div className="content">
                        <h3>Nous Administrateur ?</h3>
                        <button className="btn transparent" id="sign-up-btn" onClick={() => sign_up_btn()}>
                           S'inscrire
                        </button>
                    </div>
                    <img src="img/log.svg" className="image" alt="" />
                </div>
                {/*  ===========================================================================  */}
                <div className="panel right-panel">
                    <div className="content">
                        <h3>Déjà un compte Administrateur ??</h3>
                        <button className="btn transparent" id="sign-in-btn" onClick={() => sign_in_btn()}>
                            Connexion
                        </button>
                    </div>
                    <img src="img/register.svg" className="image" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Auth