import '../../assets/css/Toggle.css';
import React, { useState, useEffect } from 'react';
import '../../assets/css/ToggleButton.css'


const ToggleButton = ({handleClick , isToggled}) => {

    return (
        <button onClick={handleClick} className={`toggle-button ${isToggled ? 'on' : 'off'}`}></button>
    );
}

export default ToggleButton