import React from 'react';
import '../../assets/css/Dashboard.css';
import Aside from '../Aside/Aside';
import Main from './Main';
import NavBar from '../NavBar/NavBar';

const Dashboard = () => {
  return (
    <div >
      <NavBar />
      <div className='container'>
        <Aside />
        <Main />

      </div>
    </div>
  )
}

export default Dashboard