import React, { useState } from 'react'
import userIcon from '../../assets/img/user.jpg'
import '../../assets/css/Table.css'
import Form from './Form';
import Popup from '../components/Popup'

const Table = () => {

    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [itemSelected, setItemSelected] = useState({})
    const data = {
        'id': 'ds'
    }

    const openEdit = (item) => {
        setItemSelected(item)
        setIsOpenDetail(true)
    }

    return (
        <div className="recent-oders">
            <div className="container-table" style={{ paddingBottom: '0px', height: '54vh' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Profil</th>
                            <th>Nom</th>
                            <th>Email</th>
                            <th className='td_display_none'>Téléphone</th>
                            <th className='td_display_none'>Ville</th>
                            <th className='td_display_none'>Adresse</th>
                            <th colSpan={2}>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='img'><img src={userIcon} alt="profil" /></td>
                            <td>user1 user</td>
                            <td>user1.gmail.com</td>
                            <td className='td_display_none'>69778959</td>
                            <td className='td_display_none'>Yaoundé</td>
                            <td className='td_display_none'>Obobogo</td>
                            <td><button className='btn-edit' onClick={() => openEdit(data)}><span className="material-icons-sharp edit">edit</span></button></td>
                            <td><button className='btn-delete'><span className="material-icons-sharp delete">delete</span></button></td>
                        </tr>
                        <tr>
                            <td className='img'><img src={userIcon} alt="profil" /></td>
                            <td>user1 user</td>
                            <td>user1.gmail.com</td>
                            <td className='td_display_none'>69778959</td>
                            <td className='td_display_none'>Yaoundé</td>
                            <td className='td_display_none'>Obobogo</td>
                            <td><button className='btn-edit'><span className="material-icons-sharp edit">edit</span></button></td>
                            <td><button className='btn-delete'><span className="material-icons-sharp delete">delete</span></button></td>
                        </tr>
                        <tr>
                            <td className='img'><img src={userIcon} alt="profil" /></td>
                            <td>user1 user</td>
                            <td>user1.gmail.com</td>
                            <td className='td_display_none'>69778959</td>
                            <td className='td_display_none'>Yaoundé</td>
                            <td className='td_display_none'>Obobogo</td>
                            <td><button className='btn-edit'><span className="material-icons-sharp edit">edit</span></button></td>
                            <td><button className='btn-delete'><span className="material-icons-sharp delete">delete</span></button></td>
                        </tr>
                        <tr>
                            <td className='img'><img src={userIcon} alt="profil" /></td>
                            <td>user1 user</td>
                            <td>user1.gmail.com</td>
                            <td className='td_display_none'>69778959</td>
                            <td className='td_display_none'>Yaoundé</td>
                            <td className='td_display_none'>Obobogo</td>
                            <td><button className='btn-edit'><span className="material-icons-sharp edit">edit</span></button></td>
                            <td><button className='btn-delete'><span className="material-icons-sharp delete">delete</span></button></td>
                        </tr>
                        <tr>
                            <td className='img'><img src={userIcon} alt="profil" /></td>
                            <td>user1 user</td>
                            <td>user1.gmail.com</td>
                            <td className='td_display_none'>69778959</td>
                            <td className='td_display_none'>Yaoundé</td>
                            <td className='td_display_none'>Obobogo</td>
                            <td><button className='btn-edit'><span className="material-icons-sharp edit">edit</span></button></td>
                            <td><button className='btn-delete'><span className="material-icons-sharp delete">delete</span></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {isOpenDetail &&
                <Popup
                    itemSelected={itemSelected}
                    setIsOpenDetail={setIsOpenDetail}
                    Compnent={Form}
                />}
        </div>
    )
}

export default Table