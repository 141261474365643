import React from 'react'
import { Link } from 'react-router-dom'
import '../../assets/css/Aside.css'

const Aside = () => {
    return (
        <aside>
            <div className="top">
            </div>

            <div className="sidebar">
                <Link to="">
                    <span className="material-icons-sharp">grid_view</span>
                    <h2>Dashboard</h2>
                </Link>
                <Link to="/" className="active">
                    <span className="material-icons-sharp">home</span>
                    <h3>Accueil</h3>
                </Link>
                <Link to="/users" className="active" >
                    <span className="material-icons-sharp">group</span>
                    <h3>Utilisateurs</h3>
                </Link>
                <Link to="/trajets" className="active" >
                    <span className="material-icons-sharp">alt_route</span>
                    <h3>Trajet</h3>
                </Link>
                <Link to="/annonces" className="active" >
                    <span className="material-icons-sharp">campaign</span>
                    <h3>Annonces</h3>
                </Link>
                <Link to="/reservations" className="active" >
                    <span className="material-icons-sharp">pan_tool</span>
                    <h3>Reservations</h3>
                </Link>
                <Link to="/mail" className="active" >
                    <span className="material-icons-sharp">email</span>
                    <h3>Messagerie</h3>
                </Link>
                <Link to="/settings" className="active" >
                    <span className="material-icons-sharp">settings</span>
                    <h3>Paramètre</h3>
                </Link>
                <Link to="/logout" className="active" >
                    <span className="material-icons-sharp">logout</span>
                    <h3>Déconnexion</h3>
                </Link>
            </div>
            {/* <span className="message-count">26</span> */}
        </aside>
    )
}

export default Aside