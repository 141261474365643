import { DELETE_RESERVATION, GET_RESERVATION, UPDATE_RESERVATION } from "../actions/reservation.action";

const initialState = {}

export default function reservationReducer(state = initialState, action) {

    switch (action.type) {
        case GET_RESERVATION:
            return action.payload
        case UPDATE_RESERVATION:
            return state.map((reservation) => {
                if (reservation.id === action.payload.id) {
                    return {
                        ...reservation,
                        ...action.payload.reservation

                    };
                }
                return reservation
            })
        case DELETE_RESERVATION:
            return state.filter((reservation) => reservation.id !== action.payload)
        default:
            return state;
    }
}