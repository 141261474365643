import React from 'react'
import profil from '../../assets/img/user.jpg'
import '../../assets/css/NavBar.css'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const NavBar = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    return (
        <div className="topBar">
            <div className="logo">
                <h2 className="text-muted">DRIV <span className="danger">Admin</span></h2>
            </div>
            <div className="top">
                <button id="menu-btn">
                    <span className="material-icons-sharp">menu</span>
                </button>
                <div className="theme-toggler">
                    <span className="material-icons-sharp active">light_mode</span>
                    <span className="material-icons-sharp">dark_mode</span>
                </div>
                <div className='Popover'>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Typography sx={{ p: 2 }}>
                            <div id="profile-name-img">
                                <img src={profil} alt="profile" id='img-popover' />
                                <div id="profile-name">
                                    <div className="Name">KAMLA Kevin</div>
                                    <div className="mail">kvnkamla4@gmail.com</div>
                                </div>
                            </div>
                        </Typography>
                        <List>
                            <ListItem id="ListItem" onClick={() => navigate('/personnel')} ><span>Gestion des comptes</span></ListItem>
                            <ListItem id="ListItem" ><span> Paramètres</span></ListItem>
                            <ListItem id="ListItem"><span>Aide</span></ListItem>
                            <ListItem id="ListItem"><span>Se déconnecter</span></ListItem>
                        </List>
                    </Popover>
                </div>
                <div className="profile">
                    <div className="info">
                        <p><b>Kevin KAMLA</b></p>
                        <small className="danger">Administrateur</small>
                    </div>
                    <div className="profile-photo">
                        <button
                            aria-describedby={id}
                            variant="contained"
                            onClick={handleClick}
                            style={{ cursor: 'pointer', display: 'flex', background: 'transparent', borderRadius: '50%' }}
                        >
                            <img src={profil} alt="profile" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar