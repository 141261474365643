import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import NavBar from '../NavBar/NavBar'
import Aside from '../Aside/Aside'
import '../../assets/css/Paramettre.css'
import { ToastContainer } from 'react-toastify';
import ToggleButton from '../components/Toggle'
import { updateProjeProjetVersion } from '../../store/actions/projet/projetVersion.action';
import { updateTrajetPrice } from '../../store/actions/projet/projetReservPrice.action';
import { updateProjetMaintenanceMode } from '../../store/actions/projet/projetMaintenance.action';


const Paramettres = () => {
    const projet = useSelector((state) => state.projetReducer)
    const [version, setVersion] = useState(useSelector((state) => state.projetVersionReducer))

    const [versionTemp, setVersionTemp] = useState(version)
    const [isUpdateVersion, setIsUpdateVersion] = useState(false)
    const [price, setPrice] = useState(useSelector((state) => state.projetReservaPriceReducer) / 100)
    const [priceTemp, setPriceTemp] = useState(price)
    const [isUpdatePrice, setIsUpdatePrice] = useState(false)
    const [isMaintenance, setIsMaintenance] = useState(useSelector((state) => state.projetReducer).projetMaintenance);
    const dispatch = useDispatch();


    const handleClick = () => {
        dispatch(updateProjetMaintenanceMode(1, !isMaintenance))
        setIsMaintenance(!isMaintenance);
    };

    const handleUpdateVersion = (e) => {
        e.preventDefault()
        setIsUpdateVersion(false)
        setVersion(versionTemp)
        dispatch(updateProjeProjetVersion(1, versionTemp))
    }
    const handleCancelVersion = () => {
        setVersionTemp(version)
        setIsUpdateVersion(false)
    }

    const handleUpdatePrice = (e) => {
        e.preventDefault()
        setIsUpdatePrice(false)
        setPrice(priceTemp)
        dispatch(updateTrajetPrice(1, priceTemp * 100))
    }

    const handleCancelPrice = () => {
        setPriceTemp(price)
        setIsUpdatePrice(false)
    }

    return (
        <>
            <NavBar />
            <div className='container'>
                <Aside />
                <div className='containerParam'>

                    <ToastContainer />
                    <div className='itemParam'>
                        <span>Version de l'application android :
                            {!isUpdateVersion && <b style={{ color: 'blue', marginLeft: '1rem' }}> {version} </b>}
                            {isUpdateVersion &&
                                <form onSubmit={handleUpdateVersion}>
                                    <input type="text" value={versionTemp} onChange={(e) => setVersionTemp(e.target.value)}></input>
                                    <button className='buttonValidVersion' ><span className="material-icons-sharp">done</span></button>
                                    <button className='buttonCancelVersion' onClick={handleCancelVersion}><span className="material-icons-sharp">close</span></button>
                                </form>
                            }
                        </span>
                        <button className='btn-edit' onClick={() => setIsUpdateVersion(true)}><span className="material-icons-sharp edit">edit</span></button>
                    </div>
                    <div className='itemParam'>
                        <span>Prix de réservation :
                            {!isUpdatePrice && <b style={{ marginLeft: '1rem' }}> {price}$ </b>}
                            {isUpdatePrice &&
                                <form onSubmit={handleUpdatePrice}>
                                    <input type="number" value={priceTemp} onChange={(e) => setPriceTemp(e.target.value)}></input>
                                    <button className='buttonValidVersion' ><span className="material-icons-sharp">done</span></button>
                                    <button className='buttonCancelVersion' onClick={handleCancelPrice}><span className="material-icons-sharp">close</span></button>
                                </form>
                            }
                        </span>
                        <button className='btn-edit' onClick={() => setIsUpdatePrice(true)}><span className="material-icons-sharp edit">edit</span></button>
                    </div>
                    <div className='itemParam'>
                        <span>Application en maintenance</span>
                        <ToggleButton
                            handleClick={handleClick}
                            isToggled={isMaintenance}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Paramettres