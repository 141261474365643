import '../../assets/css/TrajetDetail.css'
// import Toggle from '../components/Toggle'
import React, { useEffect, useState } from 'react';
import config from '../utils/config.json'
import { useDispatch, useSelector } from 'react-redux';
import { setObjetImbrique } from '../utils/functions';
import { deleteReservation, updateReservation } from '../../store/actions/reservation.action';
import { ToastContainer } from 'react-toastify';
import { showToastWarning } from '../utils/toast';
import PopupConfirm from '../components/PopupConfirm';
import { isEmpty } from '../utils/isEmpty';

const ReservationDetail = ({ itemSelected, setIsOpenDetail }) => {
    
    const reservationReplace = useSelector((state) => state.reservationReducer)
    let reservationSelected = !isEmpty(reservationReplace) && reservationReplace?.filter(elt => elt.id === itemSelected.id)[0];
    const [isFloating, setIsFloating] = useState(false);
    const [isHandleDeleteOpen, setIsHandleDeleteOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false)
    const [formData, setFormData] = useState(itemSelected);
    const users = useSelector((state) => state.userReducer)
    const trajet = useSelector((state) => state.trajetReducer)
    const image = config.baseImage + '/' + reservationSelected.itinerary.destinationImage + '.jpg'
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (reservationSelected === formData) {
            showToastWarning("Vous n'avez rien modifié")
        } else {
            let data = {
                "seatsNumber": +formData.seatsNumber,
                "price": +formData.price,
                "passengerId": +formData.passenger.id,
                "itineraryId": +formData.itinerary.id,
            }
            console.log('Reserva  :  ',data);
            dispatch(updateReservation(formData.id, data))
            setIsUpdate(false)
        }
    }

    const handleChange = (e) => {
        if (e.target.value !== '') {
            if (e.target.name === 'passenger.id') {
                setFormData(setObjetImbrique(formData, 'passenger.id', e.target.value));
            } else if (e.target.name === 'itinerary.id') {
                setFormData(setObjetImbrique(formData, 'itinerary.id', e.target.value));
            }
            // else if (e.target.name === 'reservationDate') {
            //     let hour = String(new Date().getHours()).length === 1 ? 0 + '' + new Date().getHours() : new Date().getHours()
            //     let min = String(new Date().getMinutes()).length === 1 ? 0 + '' + new Date().getMinutes() : new Date().getMinutes()
            //     let sec = String(new Date().getSeconds()).length === 1 ? 0 + '' + new Date().getSeconds() : new Date().getSeconds()
            //     setFormData(setObjetImbrique(formData, 'reservationDate', e.target.value + 'T' + hour + ':' + min + ':' + sec));
            // }
             else {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
            console.log('formData : ', formData);
        }
    };


    const confirmDelete = (val) => {
        if (val) {
            dispatch(deleteReservation(reservationSelected.id))
                .then((res) => {
                    setIsHandleDeleteOpen(false)
                    setIsOpenDetail(false)
                })
                .catch((err) => console.log('ERREUR de suppression du trajet : ', err))
        } else {
            setIsHandleDeleteOpen(false)
        }
    }


    return (
        <>
            <div className="container-user" >
                <ToastContainer />
                <div className="popup_header d-f-c">
                    <div className="btn-header d-f-c">
                        <button className='btn-edit d-f-c' onClick={() => setIsUpdate(true)}><span className="material-icons-sharp edit">edit</span></button>
                        <button className='btn-delete d-f-c' onClick={() => setIsHandleDeleteOpen(true)}><span className="material-icons-sharp delete">delete</span></button>
                    </div>
                    <h1 className='titlee'>Information sur la reservation</h1>
                    <span className="material-icons-sharp" onClick={() => setIsOpenDetail(false)}>close</span>
                </div>
                {isUpdate && <form onSubmit={handleSubmit}>
                    <div className="div-form">
                        <div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="id">Id</label>
                                <input type="number" disabled value={formData.id} name="id" onChange={handleChange} />
                            </div>

                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="price">Montant</label>
                                <input type="number" min={1} value={formData.price || ''} name="price" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="seatsNumber">Place reservé</label>
                                <input type="number" min={1} value={formData.seatsNumber || ''} name="seatsNumber" onChange={handleChange} />
                            </div>
                        </div>
                        <div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="passenger.id">Passager</label><br />
                                <select name="passenger.id" onChange={handleChange} value={formData.passenger.id}>
                                    {/* <option value={formData.passenger.id}>{formData.passenger.name}</option> */}
                                    {
                                        users?.map((item, index) => (
                                            <option key={index} name="passenger.id" value={item.id}> {item.name}</option>
                                        ))
                                    }
                                </select>
                            </div><br />

                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="itinerary.id">Trajet</label><br />
                                <select value={formData.itinerary.id} name="itinerary.id" onChange={handleChange}>
                                    {/* <option value={formData.itinerary.id}>
                                        De {formData.itinerary?.departurePoint} à {formData.itinerary?.arrivalPoint}</option> */}
                                    {
                                        trajet?.map((item, index) => (
                                            <option key={index} name="itinerary.id" value={item.id}>
                                                De {item.departurePoint} à {item.arrivalPoint}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div><br />
                        </div>
                    </div>
                    <div className='div-btn-submit d-f-c'>
                        <button className="btn btn-annuler" onClick={() => setIsUpdate(false)} >Annuler</button>
                        <button className="btn" type="submit" >Enregistrer</button>
                    </div>
                </form >
                }
                <div className="profile_setting__bloc">
                    <div className="profile_setting__bloc_righ">
                        <div className="profile_setting">
                            <h1>Détails de la reservation</h1>
                            <div className="row">
                                <div className="label">Id</div>
                                <div className="value">{reservationSelected.id}</div>
                            </div>
                            <div className="row">
                                <div className="label">Ville de départ</div>
                                <div className="value">{reservationSelected.itinerary.departurePoint}</div>
                            </div>
                            <div className="row">
                                <div className="label">Ville d'arrivé</div>
                                <div className="value">{reservationSelected.itinerary.arrivalPoint}</div>
                            </div>
                            <div className="row">
                                <div className="label">Passager</div>
                                <div className="value">{reservationSelected.passenger.name}</div>
                            </div>
                            <div className="row">
                                <div className="label">Prix d'une reservation</div>
                                <div className="value">${reservationSelected.price}</div>
                            </div>
                            <div className="row">
                                <div className="label">Montant</div>
                                <div className="value">${(reservationSelected.price * reservationSelected.seatsNumber).toFixed(2)}</div>
                            </div>
                            <div className="row">
                                <div className="label">Place reservées</div>
                                <div className="value">{reservationSelected.seatsNumber}</div>
                            </div>
                            <div className="row">
                                <div className="label">Date et heure</div>
                                <div className="value">{reservationSelected.reservationDate?.split('T')[0] + ' à ' + reservationSelected.reservationDate?.split('T')[1]}</div>
                            </div>
                            <div className="row">
                                <div className="label">Conducteur</div>
                                <div className="value">{reservationSelected.itinerary.conductor.name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="profile_setting__blocLeft">
                        <div className="profile_setting__image">
                            {image &&
                                <>
                                    <img style={{ borderRadius: "20px" }} src={image} alt="" />
                                </>
                            }
                            <h1>{reservationSelected.itinerary.arrivalPoint}</h1>
                            <br />
                        </div>

                    </div>
                </div>
            </div>

            {
                isHandleDeleteOpen ?
                    <PopupConfirm
                        title='Vous êtes sur le point de supprimer définitivement cette reservation ?'
                        action={confirmDelete}
                    /> : <></>
            }

        </>

    )
}

export default ReservationDetail 