import React, { useEffect, useState } from 'react'
import '../../assets/css/BoxInfo.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
// import Skeleton from '@mui/material/Skeleton';




const BoxInfo = ({ boxclass, icon, nameInfo, value, nbUserReserve, root }) => {

    const userInit = useSelector((state) => state.userReducer)
    const [unite, setUnite] = useState("")
    const [prctgreservation, setPrctgreservation] = useState(0)

    useEffect(() => {
        if (nameInfo === "Paiement") {
            setUnite("$")
        }
        if (nameInfo === "Réservation") {
            setPrctgreservation(nbUserReserve*100/userInit.length)
            console.log('nbUSER : ', userInit.length , '\n nbUserReserve :', nbUserReserve , '\n pourcetage', nbUserReserve*100/userInit.length);
        }
        
    }, [nameInfo, nbUserReserve, prctgreservation, userInit.length])


    return (
        <div className={boxclass}>
            <span className="material-icons-sharp">{icon}</span>
            <Link to={root}>
                <div className="milieu">
                    <div className="left">
                        <h3>{nameInfo}</h3>
                        {/* {value ? */}
                        <h1>{unite}{value}</h1>
                        {/* : */}
                        {/* <Skeleton height={10} width='50%' style={{ margin: 6 }} /> */}
                        {/*  } */}
                    </div>
                    {nameInfo === "Réservation" ?
                        <div className="progres">
                            <div className="numero" style={{ width: prctgreservation.toFixed(1) + "%" }}>
                                <p style={prctgreservation.toFixed() <= 5 ? { color: 'black' } : { color: 'white' }}>{prctgreservation.toFixed(0)}%</p>
                            </div>
                        </div>
                        : <></>
                    }
                </div>
            </Link>
            <small className="titi">Les derniers 24h</small>
        </div>
    )
}

export default BoxInfo