/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/heading-has-content */
import Aside from "../Aside/Aside"
import NavBar from "../NavBar/NavBar"
import userIcon from '../../assets/img/user.jpg'
import '../../assets/css/Popup.css'
import '../../assets/css/Conductor.css'
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { showToastError, showToastWarning } from '../utils/toast';
import { ToastContainer } from 'react-toastify';
import { isEmpty } from "../utils/isEmpty"
import { sendEmail } from "../../store/actions/notification/mail.action"


const SendMail = () => {

  let email = useSelector((state) => state.sendMailReducer)
  // const [isOpenDetail, setIsOpenDetail] = useState(false)
  const [isTranslate, setIsTranslate] = useState(false)
  const [isDisplayResultSearch, setIsDisplayResultSearch] = useState(false);
  const [isDisplayResultSearchForSelected, setIsDisplayResultSearchForSelected] = useState(false);
  const [conducteurResultBySearch, setConducteurResultBySearch] = useState(useSelector((state) => state.conductorReducer))
  const [conducteurResulteBySelector, setConducteurResulteBySelector] = useState(useSelector((state) => state.conductorReducer))
  const [conducteur, setConducteur] = useState(useSelector((state) => state.conductorReducer))
  const [conductorsSelected, setConductorsSelected] = useState([]);
  const [mailConductorsSelected, setMailConductorsSelected] = useState([]);
  const [formMail, setFormMail] = useState({
    subject: '',
    body: '',
  });
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const dispach = useDispatch()
  const [active, setActive] = useState('')
  const isSendMail = "sendMail"

  const handleSearchForDisplay = (e) => {
    e.preventDefault();
    let val = e.target.value;
    if (val) {
      let resultats = conducteur.filter((item) => {
        let nom = item.name + ' ' + item.email + ' ' + item.phone;
        return nom.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
      if (resultats.length === 0) setIsDisplayResultSearch(false)
      else setIsDisplayResultSearch(true)

      setConducteurResultBySearch(resultats)
    } else {
      setConducteurResultBySearch(conducteur)
    }
  };

  const handleSearchForSelector = (e) => {
    e.preventDefault();
    let val = e.target.value;
    if (val) {
      let resultats = !isEmpty(conducteur) && conducteur?.filter((item) => {
        let nom = item.name + ' ' + item.email + ' ' + item.phone;
        return nom.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
      if (resultats.length === 0) setIsDisplayResultSearchForSelected(false)
      else setIsDisplayResultSearchForSelected(true)

      setConducteurResulteBySelector(resultats)
    } else {
      setConducteurResulteBySelector(conducteur)
    }
  }

  // const handleActive = () => {
  //   setActive('active')
  // }


  const addConductorSelected = (elt) => {
    if (conductorsSelected.includes(elt)) {
      showToastWarning('Le conducteur ' + elt.name + ' est déjà selectionné')
    } else {
      setConductorsSelected([...conductorsSelected, elt])
      setMailConductorsSelected([...mailConductorsSelected, elt.email])
    }
  }


  const removeItem = (item) => {
    let data = conductorsSelected.filter((elt) => elt.id !== item.id)
    setConductorsSelected(data);
    let dat = mailConductorsSelected?.filter((elt) => elt !== item.email)
    setMailConductorsSelected(dat)
  }

  useEffect(() => {
    console.log('email changed ', email);
  }, [email])


  const validateConductorsSelected = () => {
    setIsTranslate(!isTranslate)
    console.log('mail validate : ', mailConductorsSelected);
  }


  const handleChange = (e) => {
    if (e.target.value !== '') {
      if (e.target.name === 'file') {
        const reader = new FileReader();
        const Myfile = e.target.files[0];

        reader.onloadend = () => {
          setFile(Myfile);
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(Myfile);
      } else setFormMail({ ...formMail, [e.target.name]: e.target.value });

    }
  };

  const handleSendMail = (e) => {
    e.preventDefault()
    if (mailConductorsSelected.length === 0) {
      showToastError("Veillez selectionner un destinataire svp")
    } else if (!formMail.subject && !formMail.body) {
      showToastError("Veillez remplir l'objet et le contenu du mail svp")
    } else {
      console.log('mailConductorsSelected : ', mailConductorsSelected);
      let cc = mailConductorsSelected.filter((mail) => mail !== mailConductorsSelected[0])
      const form = {
        to: mailConductorsSelected[0],
        cc: cc,
        subject: formMail.subject,
        body: formMail.body,
        file: [
          file
        ]
      }
      dispach(sendEmail(form))

      // console.log("mail final reducer: ", email);

    }
  }


  return (
    <div >
      <NavBar />
      <div className='container'>
        <Aside />
        <div className="mail-container">
          <ToastContainer />
          <div className="first-bloc">
            <div className="bloc-button">
              <button className='button-new-mail' onClick={() => setIsTranslate(!isTranslate)} >Nouveau mail</button>
            </div>
            <div className="search-conductor">
              <div className="bloc-input">
                <span className="material-icons-sharp">search</span>
                <input type="text" placeholder='Par sujet' onChange={e => handleSearchForDisplay(e)} />
              </div>
              {isDisplayResultSearch &&
                <div className="conductor_search">
                  <ul>
                    {conducteurResultBySearch.map((elt, index) =>
                      <li key={index} >
                        {elt.profilePicture === null ?
                          <img src={userIcon} alt="profil" />
                          :
                          <img src={elt.profilePicture} alt="profil" />
                        }
                        {elt.name}
                      </li>
                    )}
                  </ul>
                </div>
              }
            </div>
            <div className="list-mail">
              <ul>
                {email.map((elt, index) =>
                  <li className={"item " + (active)} key={index}>
                    <div className="title">{elt.subject}</div>
                    <div className="previsual-content">{elt.body}</div>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="second-bloc">
            <form onSubmit={handleSendMail}>
              {/* <div className="bloc-header">
                <h2 className="mail-title"></h2>
              </div> */}
              <div className="bloc-content-mail">
                <div className="bloc-objet">
                  <label htmlFor="objet">Objet:</label>
                  <input type="text" id="objet" name="subject" value={formMail.subject} onChange={handleChange} />
                </div>
                <div className="mail_content">
                  <div className={"bloc-text "}>
                    {/* <div className="mail mail_receive">
                    <div className="text">
                      Dans cet exemple, lorsque vous cliquez sur un l’effet ne persiste que pendant
                    </div>
                    <div className="date">19/01/2024 à 12h:09</div>
                  </div> */}
                  </div>
                  {email.sort((a, b) => b.id - a.id).map((mail, index) =>
                    <div key={index} className={"bloc-text " + (isSendMail === 'sendMail' && 'mail_send')}>
                      <div className="mail mail_send">
                        <div className="text">
                          {mail.body}
                        </div>
                        <div className="date">19/01/2024 à 12h:09</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {imagePreviewUrl && <img src={imagePreviewUrl} alt="Preview" className="imgPreview" />}
              <div className="bloc-footer">
                <label htmlFor="file"><span className="material-icons-sharp">attach_file</span></label>
                <input type="file" onChange={handleChange} name="file" id="file" className="d-n" />
                <textarea type="text" id="content" name="body" value={formMail.body} onChange={handleChange} ></textarea>
                <button type="submit" className="btn-send-mail"><span className="material-icons-sharp">send</span></button>
              </div>
            </form>
          </div>
          <div className={"three-bloc " + (isTranslate ? 'translate' : '')}  >
            <div className="search_conductorFor_Selector">
              <div className="bloc-input">
                <span className="material-icons-sharp">search</span>
                <input type="text" placeholder='Rechercher par nom, mail ou numéro' onChange={e => handleSearchForSelector(e)} />
              </div>
              {isDisplayResultSearchForSelected &&
                <div className="conductor_search_for_selector">
                  <ul>
                    {!isEmpty(conducteurResulteBySelector) && conducteurResulteBySelector?.map((elt, index) =>
                      <li key={index} onClick={() => addConductorSelected(elt)}>
                        {elt.profilePicture === null ?
                          <img src={userIcon} alt="profil" />
                          :
                          <img src={elt.profilePicture} alt="profil" />
                        }
                        {elt.name}
                      </li>
                    )}
                  </ul>
                </div>
              }
            </div>
            <div className="">
              {conductorsSelected.length !== 0 && <h3>Liste des conducteurs sélectionné({conductorsSelected.length}) </h3>}
              <div className="destinataire_final">
                {conductorsSelected.length !== 0 && conductorsSelected.map((elt, index) =>
                  <b key={index}>{elt.name}
                    <span className="material-icons-sharp closeRemoveItem" onClick={() => removeItem(elt)}>close</span>
                  </b>
                )}
              </div>
              {conductorsSelected.length !== 0 && <button className="button-new-mail" onClick={() => validateConductorsSelected()}>Rédiger</button>}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SendMail

