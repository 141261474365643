import React, { useState } from 'react'
import '../../assets/css/FormContainer.css'

const Form = ({ itemSelected, setIsOpenDetail }) => {

    const [isFloating, setIsFloating] = useState(false);
    const [formData, setFormData] = useState(itemSelected);
    const roles = ['Administrateur', 'Controleur', 'Modificateur']
    const [selectAll, setSelectAll] = useState(false);
    const [options, setOptions] = useState({
        option1: false,
        option2: false,
        option3: false
    });
    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setOptions({ option1: newSelectAll, option2: newSelectAll, option3: newSelectAll });
    };

    const handleSingleCheck = (event) => {
        setOptions({ ...options, [event.target.name]: event.target.checked });
    };

    const handleChange = () => {

    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }


    return (
        <div className='form_container'>
            <div className="header">
                <span className="material-icons-sharp" onClick={() => setIsOpenDetail(false)}>close</span>
            </div>
            <form onSubmit={handleSubmit}>
                {itemSelected ? <h1>Edition d'un membre</h1> : <h1>Ajout d'un membre</h1>}
                <div className="div-form">
                    <div>
                        {itemSelected &&
                            <>
                                <div id="float-label">
                                    <label className={isFloating ? 'Active' : ''} htmlFor="id">Id</label>
                                    <input className='' type="text" disabled value={formData?.id} name="id" onChange={handleChange} />
                                </div>
                                <div id="float-label">
                                    <label className={isFloating ? 'Active' : ''} htmlFor="Date">Date</label>
                                    <input type="date" value={formData?.Date?.split('T')[0]} name="Date" onChange={handleChange} />
                                </div>
                            </>
                        }
                        <div id="float-label">
                            <label className={isFloating ? 'Active' : ''} htmlFor="name">Nom</label>
                            <input type="text" value={formData?.name} name="name" onChange={handleChange} />
                        </div>
                        <div id="float-label">
                            <label className={isFloating ? 'Active' : ''} htmlFor="email">Email</label>
                            <input type="mail" value={formData?.email} name="email" onChange={handleChange} />
                        </div>
                        <div id="float-label">
                            <label className={isFloating ? 'Active' : ''} htmlFor="phone">Numéro</label>
                            <input type="number" value={formData?.phone} name="phone" onChange={handleChange} />
                        </div>
                    </div>
                    <div>
                        <div id="float-label">
                            <label className={isFloating ? 'Active' : ''} htmlFor="city">Ville</label>
                            <input type="text" value={formData?.city} name="city" onChange={handleChange} />
                        </div>
                        <div id="float-label">
                            <label className={isFloating ? 'Active' : ''} htmlFor="completeAdresse">Addresse</label>*
                            <input type="text" value={formData?.completeAdresse || ' '} name="completeAdresse" onChange={handleChange} />
                        </div>
                        <div id="float-label">
                            <label className={isFloating ? 'Active' : ''} htmlFor="users.id">Rôle</label><br />
                            <select name="role" onChange={handleChange}>
                                <option>--Séléctionner--</option>
                                {
                                    roles?.map((item, index) => (
                                        <option key={index} name="role" value={item}> {item}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="bloc_checkbox">
                            <div id="float-label">
                                Tout sélectionner
                                <label htmlFor='option'>
                                    <input type="checkbox" name="option" id='option' checked={selectAll} onChange={handleSelectAllChange} />
                                </label>
                            </div>
                            <div id="float-label">
                                <label htmlFor='option1'>
                                    Option 1
                                    <input type="checkbox" name="option1" id='option1' value={true} checked={options.option1} onChange={handleSingleCheck} />
                                </label>
                            </div>
                            <div id="float-label">
                                <label htmlFor='option2'>
                                    Option 2
                                    <input type="checkbox" name="option2" id='option2' checked={options.option2} onChange={handleSingleCheck} />
                                </label>
                            </div>
                            <div id="float-label">
                                <label htmlFor='option3'>
                                    Option 3
                                    <input type="checkbox" name="option3" id='option3' checked={options.option3} onChange={handleSingleCheck} />
                                </label>
                            </div>
                            <div id="float-label">
                                <label htmlFor='option2'>
                                    Option 2
                                    <input type="checkbox" name="option2" id='option2' checked={options.option2} onChange={handleSingleCheck} />
                                </label>
                            </div>
                            <div id="float-label">
                                <label htmlFor='option3'>
                                    Option 3
                                    <input type="checkbox" name="option3" id='option3' checked={options.option3} onChange={handleSingleCheck} />
                                </label>
                            </div>
                        </div>
                    </div><br />

                </div>
                <div className='div-btn-submit d-f-c'>
                    <button className="btn1 btn-annuler" onClick={() => setIsOpenDetail(false)} >Annuler</button>
                    <button className="btn1" type="submit" >Enregistrer</button>
                </div>
            </form>
        </div>
    )
}

export default Form