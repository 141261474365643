import axios from 'axios';
import { api_route } from '../../../pages/utils/api_routes';
import { useState } from 'react';
import { showToastError, showToastSuccèss } from '../../../pages/utils/toast';

export const GET_PROJET = "GET_PROJET";

export const getProjet = (id =1) => {
    return async (dispatch) => {
        return await axios.get(api_route?.projet.url + 'read/' + id)
            .then((res) => {
                dispatch({ type: GET_PROJET, payload: res.data })
            });
    }
}
