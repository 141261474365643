import axios from 'axios';
import { api_route } from '../../pages/utils/api_routes';
import { showToastError, showToastSuccèss } from '../../pages/utils/toast';

export const GET_ANNONCE = "GET_ANNONCE";
export const DELETE_ANNONCE = "DELETE_ANNONCE";
export const UPDATE_ANNONCE = "UPDATE_ANNONCE";

export const getAnnonce = () => {
    return async (dispatch) => {
        return await axios.get(api_route?.annonces.url + 'announce')
            .then((res) => {
                dispatch({ type: GET_ANNONCE, payload: res.data })
            });
    }
}


export const deleteAnnonce = (id) => {
    return async (dispatch) => {
        return await axios.delete(api_route?.annonces.url + 'announce/' + id)
            .then((res) => {
                dispatch({ type: DELETE_ANNONCE, payload: id })
                showToastSuccèss('Cette annonce à été supprimé avec succès')
            })
            .catch(err => {
                showToastError("Erreur de suppression de cette annonce")
                console.log("Erreur de suppression de cette annonce : ", err)
            }
            )
    }
}

export const updateAnnonce = (id, annonce) => {
    console.log('Affiche : ', annonce);
    return async (dispatch) => {
        return await axios.put(api_route?.annonces.url + 'announce/' + id, annonce)
            .then((res) => {
                dispatch({ type: UPDATE_ANNONCE, payload: { id, annonce } })
                showToastSuccèss('Cette annonce a été modifié avec succès')
            })
            .catch(err => {
                showToastError("Erreur de modification de cette annonce")
                console.log("Erreur de modification de cette annonce : ", err.response.data)
            })
    }
}