import axios from 'axios';
import { api_route } from '../../../pages/utils/api_routes';
import { useState } from 'react';
import { showToastError, showToastSuccèss } from '../../../pages/utils/toast';


export const UPDATE_PROJET_MAINTENANCE = "UPDATE_TRAJET_MAINTENANCE";


export const updateProjetMaintenanceMode = (id, value) => {
    return async (dispatch) => {
        return await axios.put(api_route?.projet.url + 'setMaintenanceMode/' + id + '/' + value)
            .then((res) => {
                // dispatch({ type: UPDATE_PROJET_MAINTENANCE, payload: { id, value } })
                showToastSuccèss("Etat de maintenance modifié avec succèss")
            })
            .catch(err => showToastError("Erreur de modification de l'état de maintenance ", err))
    }
}

