
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showToastSuccèss = (sms = 'Action réussi avec succès') => {
    toast(sms, {
        type: 'success',
        duration: 3000,
        position: 'top-right',
        icon: '\uD83D\uDC4F',
    });
}

export const showToastError = (sms = "Une erreur s'est produite") => {
    toast(sms, {
        type: 'error',
        duration: 5000,
        position: 'top-center',
        icon: '\u274C'
    });
}

export const showToastWarning = (sms = "Avertissement") => {
    toast(sms, {
        type: 'warning',
        duration: 3000,
        position: 'top-center',
        icon: '\u26A0'
    });
}