import React, { useState } from 'react'
import NavBar from '../NavBar/NavBar'
import Aside from '../Aside/Aside'
import Table from './Table';
import { useSelector } from 'react-redux';

const Annonces = () => {

  const annonceInit = useSelector((state) => state.annonceReducer)
  const [annonce, setAnnonce] = useState(annonceInit)
  const [tableInit, setTableInit] = useState(true)
  const [tableSearch, setTableSearch] = useState(false)

  const handleSearch = (e) => {
    e.preventDefault();
    let val = e.target.value;
    if (val) {
      let resultats = annonce.filter((item) => {
        let nom = item.departurePoint + ' ' + item.arrivalPoint + ' ' + item.users.name;
        return nom.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
      setTableInit(false)
      setTableSearch(true)
      setAnnonce(resultats)
    } else {
      setAnnonce(annonceInit)
    }
    if (!annonce) {
      setAnnonce(annonceInit)
    }
  };


  return (
    <>
      <NavBar />
      <div className='container'>
        <Aside />
        <div>
          <div className="search">
            <div className="bloc-input">
              <span className="material-icons-sharp">search</span>
              <input type="text" placeholder='Taper ici ...' onChange={e => handleSearch(e)}/>
              <button className='button'>Rechercher</button>
            </div>
          </div>
          {tableInit && <Table annonce={annonceInit} titleTable={'Liste des annonces déjà créées'} />}
          {tableSearch && <Table annonce={annonce} titleTable={'Liste des annonces déjà créées'} />}
        </div>
      </div>
    </>
  )
}

export default Annonces