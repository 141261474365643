import '../../assets/css/Table.css'
import { useState } from 'react';
import Popup from '../components/Popup';
import { useLocation } from 'react-router-dom';
import AnnonceDetail from './AnnonceDetail';


const Table = ({ annonce, titleTable }) => {

    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [annonceSelected, setAnnonceSelected] = useState({})
    const location = useLocation();

    const goToDetailAnnonce = (id) => {
        let anno = annonce.filter(elt => elt.id === id)[0];
        setIsOpenDetail(true);
        setAnnonceSelected(anno);
        // navigate('userDetail')
    }

    // console.log(location.pathname);

    return (
        <>
            <div className="recent-oders">
                <h2>{titleTable} ({annonce?.length})</h2>
                <div className="container-table"
                    style={location.pathname === '/' ? { paddingBottom: '0px', height: '54vh' } : {}}
                >
                    <table>
                        <thead>
                            <tr>
                                <th>Ville de départ</th>
                                <th>Ville d'arrivé</th>
                                <th className='td_display_none'>Place reservée</th>
                                <th className='td_display_none'>Date et heure</th>
                                <th>Passager</th>
                            </tr>
                        </thead>
                        <tbody>
                            {annonce?.length > 0 && [...annonce].sort((a, b) => b.id - a.id).map((elt, index) =>
                                <tr key={index} onClick={() => goToDetailAnnonce(elt.id)}>
                                    <td>{elt.departurePoint}</td>
                                    <td>{elt.arrivalPoint}</td>
                                    <td className='td_display_none'>{elt.seatReserved}</td>
                                    <td className='td_display_none'>{elt.departureDate?.split('T')[0]} à {elt.departureDate?.split('T')[1]}</td>
                                    <td>{elt.users?.name}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
                <a href="#"> Voir tout </a>

            </div >

            {isOpenDetail ?
                <Popup itemSelected={annonceSelected}
                    setIsOpenDetail={setIsOpenDetail}
                    Compnent={AnnonceDetail}
                /> : <></>}
        </>
    )
}

export default Table
