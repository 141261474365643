import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import { Route, Routes } from 'react-router-dom';
import Users from './pages/Users/Users';
import Trajet from './pages/Trajets/Trajets'
import Annonces from './pages/Annonces/Annonces'
import Signin from './pages/Auth/Signin/Signin'
import Auth from './pages/Auth/Auth';
import UserDetail from './pages/Users/UserDetail';
import Reservations from './pages/Reservations/Reservations';
import SendMail from './pages/Conductor/SendMail';
import Personnel from './pages/Personels/Personnel';
import Paramettres from './pages/Paramettres/Paramettres';

function App() {

  return (

    <div className='gobalDiv'>
      <Routes>
        <Route index path='/' element={<Dashboard />} />
        <Route path='users' element={<Users />} />
        <Route path='trajets' element={<Trajet />} />
        <Route path='annonces' element={<Annonces />} />
        <Route path='reservations' element={<Reservations />} />
        <Route path='mail' element={<SendMail />} />
        <Route path='personnel' element={<Personnel />} />
        <Route path='settings' element={<Paramettres />} />
        <Route path='logout' element={<Auth />} />
      </Routes>
    </div>

  );
}

export default App;
