import { useSelector } from 'react-redux'
import '../../assets/css/Reservations.css'
import Aside from '../Aside/Aside'
import NavBar from '../NavBar/NavBar'
import Table from './Table'
import { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';

const Reservations = () => {


    const reservationInit = useSelector((state) => state.reservationReducer)
    const [reservation, setReservation] = useState(useSelector((state) => state.reservationReducer))
    const [tableInit, setTableInit] = useState(true)
    const [tableSearch, setTableSearch] = useState(false)

    const handleSearch = (e) => {
        e.preventDefault();
        let val = e.target.value;
        if (val) {
            let resultats = reservation.filter((item) => {
                let nom = item.itinerary.departurePoint + ' ' + item.itinerary.arrivalPoint + ' ' + item.passenger.name + ' ' + item.itinerary.conductor.name;
                return nom.toLowerCase().indexOf(val.toLowerCase()) > -1;
            })
            setTableInit(false)
            setTableSearch(true)
            setReservation(resultats)
        } else {
            setReservation(reservationInit)
        }
        if (!reservation) {
            setReservation(reservationInit)
        }
    };


    return (
        <>
            <NavBar />
            <div className='container'>
                <Aside />
                <div>
                    <div className="search">
                        <div className="bloc-input">
                            <span className="material-icons-sharp">search</span>
                            <input type="text" placeholder='Taper ici ...' onChange={e => handleSearch(e)} name="" id="" />
                            <button className='button'>Rechercher</button>
                        </div>
                    </div>
                    {tableInit && <Table reservation={reservationInit} titleTable={"Liste des reservations"} />}
                    {tableSearch && <Table reservation={reservation} titleTable={"Liste des reservations trouvés"} />}
                </div>

            </div>
        </>
    )
}

export default Reservations