import React, { useState } from 'react'
import NavBar from '../NavBar/NavBar'
import '../../assets/css/Personnel.css'
import Table from './Table'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Popup from '../components/Popup';
import Form from './Form';

const Personnels = () => {

    const [isOpenDetail, setIsOpenDetail] = useState(false)

    const navigate = useNavigate();

    return (
        <>
            <NavBar />
            <button className="btn-back" onClick={() => navigate(-1)}>
                <span class="material-icons-sharp">keyboard_backspace</span>
            </button>
            <div className='container_personnel'>
                <div className="add_user">
                    <button className='btn-add-user' onClick={()=>setIsOpenDetail(true)}>Ajouter un utilisateur<span className="material-icons-sharp edit">border_color</span></button>
                </div>
                <h2>Liste des membres</h2>
                <Table />
            </div>

            {isOpenDetail &&
                <Popup 
                    setIsOpenDetail={setIsOpenDetail}
                    Compnent={Form}
                />}
        </>
    )
}

export default Personnels