import React, { useState } from 'react'
import Aside from '../Aside/Aside'
import NavBar from '../NavBar/NavBar'
import Table from './Table';
import { useSelector } from 'react-redux';

const Trajets = () => {

  const trajetInit = useSelector((state) => state.trajetReducer)
  const [trajet, setTrajet] = useState(useSelector((state) => state.trajetReducer))
  const [tableInit, setTableInit] = useState(true)
  const [tableSearch, setTableSearch] = useState(false)

  const handleSearch = (e) => {
    e.preventDefault();
    let val = e.target.value;
    if (val) {
      let resultats = trajet.filter((item) => {
        let nom = item.departurePoint + ' ' + item.arrivalPoint + ' ' + item.conductor.name;
        return nom.toLowerCase().indexOf(val.toLowerCase()) > -1;
      })
      setTableInit(false)
      setTrajet(resultats)
      setTableSearch(true)
    } else {
      setTrajet(trajetInit)
    }
    if (!trajet) {
      setTrajet(trajetInit)
    }
  };


  return (
    <>
      <NavBar />
      <div className='container'>
        <Aside />
        <div>
          <div>
            <div className="search">
              <div className="bloc-input">
                <span className="material-icons-sharp">search</span>
                <input type="text" placeholder='Taper ici ...' onChange={e => handleSearch(e)} />
                <button className='button'>Rechercher</button>
              </div>
            </div>
          </div>
          {tableInit && <Table trajet={trajetInit} titleTable={'Liste des trajets déjà créés'} />}
          {tableSearch && <Table trajet={trajet} titleTable={'Liste des trajets trouvés'} />}
        </div>
      </div>
    </>
  )
}

export default Trajets