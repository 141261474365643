import React from 'react'
import BoxInfo from './BoxInfo'
import '../../assets/css/Main.css'
import Table from '../Users/Table'
import { useState, useEffect } from 'react';
import { api_route } from '../utils/api_routes';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { isEmpty } from '../utils/isEmpty';

const Main = () => {

    const usersInit = useSelector((state) => state.userReducer)
    const usersFilter = usersInit.filter(elt => elt.driverLicensePicture != null);
    const [users, setUsers] = useState(useSelector((state) => state.userReducer))
    const [annonce, setAnnounce] = useState(useSelector((state) => state.annonceReducer))
    const [trajet, setTrajet] = useState(useSelector((state) => state.trajetReducer))
    const [reservation, setReservation] = useState(useSelector((state) => state.reservationReducer))
    const isTableUserDashboar = true
    const [totalPayement, setTotalPayement] = useState(0)
    const [nbuser, setNbuser] = useState(0)
    const [nbannounce, setNbAnnounce] = useState(0)
    const [nbtrajet, setNbTrajet] = useState(0)
    const [nbreservation, setNbReservation] = useState(0)
    const [nbUserReserve, setNbUserReserve] = useState(0)

    //=================== user ==============================
    useEffect(() => {
        if (!isEmpty(usersInit)) {
            // setNbuser(usersInit?.length)
            // filterByDriveLicence(usersInit)
        } else {
            const getUser = async () => {
                try {
                    const response = await axios.get(api_route?.users.url + 'user', {
                        headers: {
                            'Cache-Control': 'max-age=3600',
                        }
                    });
                    if (response.status === 200) {
                        setNbuser(response.data.length);
                        filterByDriveLicence(response.data)
                    }
                } catch (error) {
                    console.log(" ❌ Erreur de récupération des données : ", error);
                }
            }
            getUser();
        }

    }, [])


    //=================== annonce ==============================
    useEffect(() => {
        if (!isEmpty(annonce)) {
            setNbAnnounce(annonce?.length)
        } else {
            const getAnnounce = async () => {
                try {
                    const response = await axios.get(api_route?.annonces.url + 'announce', {
                        headers: {
                            'Cache-Control': 'max-age=3600',
                        }
                    });
                    if (response.status === 200) {
                        setNbAnnounce(response.data.length);
                    }
                } catch (error) {
                    console.log(" ❌ Erreur de récupération des données : ", error);
                }
            }
            getAnnounce();
        }
    }, [])


    //=================== trajet ==============================
    useEffect(() => {
        if (!isEmpty(trajet)) {
            setNbTrajet(trajet?.length)
        } else {
            const getTrajet = async () => {
                try {
                    const response = await axios.get(api_route?.trajet.url + 'itinerary', {
                        headers: {
                            'Cache-Control': 'max-age=3600',
                        }
                    });
                    if (response.status === 200) {
                        setNbTrajet(response.data.length);
                    }
                } catch (error) {
                    console.log(" ❌ Erreur de récupération des données : ", error);
                }
            }
            getTrajet();
        }
    }, [])


    //=================== reservation ==============================
    useEffect(() => {
        if (!isEmpty(reservation)) {
            setNbReservation(reservation?.length)
            sumPaitement(reservation);
        } else {
            const getReservation = async () => {
                try {
                    const response = await axios.get(api_route?.reservation.url + 'reservation', {
                        headers: {
                            'Cache-Control': 'max-age=3600',
                        }
                    });
                    if (response.status === 200) {
                        setNbReservation(response.data.length);
                        setReservation(response.data)
                        sumPaitement(response.data);
                    }
                } catch (error) {
                    console.log(" ❌ Erreur de récupération des données : ", error);
                }
            }
            getReservation();
        }
    }, [])


    const sumPaitement = (reservation) => {
        let total = 0;
        reservation.forEach((elt) => { total += (elt.seatsNumber * (elt.price)) })
        setTotalPayement(total)
        let IDsResev = [];
        reservation.forEach((resev) => {
            IDsResev.push(resev.passenger.id)
        })
        console.log('IDsResev : ', IDsResev); 
        setNbUserReserve((new Set(IDsResev).size))
        console.log('nb : ', nbUserReserve);
    }


    const filterByDriveLicence = (data) => {
        if (data?.length > 0) {
            let result = data?.filter(elt => elt.driverLicensePicture != null);
            setUsers(result)
        }
    }


    return (
        <main>
            <div className="insi">
                <BoxInfo boxclass="user" icon="group" nameInfo="Utilisateurs" value={usersInit.length} root={'/users'} />
                <BoxInfo boxclass="prix" icon="alt_route" nameInfo="Trajet" value={nbtrajet} root={'/trajets'} />
                <BoxInfo boxclass="donnees" icon="campaign" nameInfo="Annonces" value={nbannounce} root={'/annonces'} />
                <BoxInfo boxclass="info" icon="pan_tool" nameInfo="Réservation" value={nbreservation}
                    nbUserReserve={nbUserReserve} root={'/reservations'} />
                <BoxInfo boxclass="prix" icon="monetization_on" nameInfo="Paiement" value={totalPayement.toFixed(2)} />
            </div>
            {usersFilter ?
                <Table
                    isTableUserDashboar={isTableUserDashboar}
                    users={usersFilter}
                    titleTable="Utilisateurs ayant envoyés leur permis de conduire"
                />
                :
                <Table
                    isTableUserDashboar={isTableUserDashboar}
                    users={users}
                    titleTable="Utilisateurs ayant envoyés leur permis de conduire"
                />
            }

        </main>
    )
}

export default Main