import '../../assets/css/TrajetDetail.css'
import React, { useState, useEffect } from 'react';
import config from '../utils/config.json'
import { useSelector, useDispatch } from 'react-redux';
import { doPost } from '../utils/services';
import { deleteTrajet, updateTrajet } from '../../store/actions/trajet.action';
import { isEmpty } from '../utils/isEmpty';
import { setObjetImbrique } from '../utils/functions';
import PopupConfirm from '../components/PopupConfirm';
import 'react-toastify/dist/ReactToastify.css';
import { showToastWarning } from '../utils/toast';
import { ToastContainer } from 'react-toastify';


const TrajetDetail = ({ itemSelected, setIsOpenDetail }) => {
    const trajetInit = useSelector((state) => state.trajetReducer)
    let trajetSelected = !isEmpty(trajetInit) && trajetInit?.filter(elt => elt.id === itemSelected.id)[0];
    const [formData, setFormData] = useState(trajetSelected);
    
    const reservation = useSelector((state) => state.reservationReducer)
    const [userResevation, setUserReservation] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [isFloating, setIsFloating] = useState(false);
    const [isHandleDeleteOpen, setIsHandleDeleteOpen] = useState(false);
    const dispatch = useDispatch();

    let trajetId = itemSelected.id
    const image = config.baseImage + '/' + trajetSelected.destinationImage + '.jpg'



    const handleChange = (e) => {
        if (e.target.name === 'conductor.name') {
            setFormData(setObjetImbrique(formData, 'conductor.name', e.target.value));
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
        console.log('formData : ', formData);
    };


    useEffect(() => {
        const getUserByReservationItinerary = async () => {
            setUserReservation([])
            isEmpty(reservation) && reservation.forEach(element => {
                if (element.itinerary.id === trajetId) {
                    setUserReservation(preuserResevation => [...preuserResevation, element.users.name])
                }
            });
        }
        getUserByReservationItinerary();
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        if (trajetSelected === formData) {
            showToastWarning("Veillez modifier au moins un champ avant d'enregistrer")
        } else {
            let data = {
                "price": formData.price,
                "departureDate": formData.departureDate,
                "seatAvailable": +formData.seatAvailable,
                "seatReserved": +formData.seatReserved,
                "departurePoint": formData.departurePoint,
                "arrivalPoint": formData.arrivalPoint,
                "destinationImage": formData.destinationImage,
                "conductorId": +formData.conductor.id,
                "meetingPoint": formData.meetingPoint,
                "destinationPoint": formData.destinationPoint,
                "driverNote": formData.driverNote,
            }
            dispatch(updateTrajet(trajetSelected.id, data))
                .then((res) => { })
                .catch((err) => { console.log('Erreur de la modification : ', err); })

            setIsUpdate(false)
        }
    }

    const confirmDelete = (val) => {
        if (val) {
            dispatch(deleteTrajet(trajetSelected.id))
                .then((res) => {
                    setIsHandleDeleteOpen(false)
                    setIsOpenDetail(false)
                })
                .catch((err) => console.log('ERREUR de suppression du trajet : ', err))
        } else {
            setIsHandleDeleteOpen(false)
        }
    }


    return (
        <>
            <div className="container-user" >
                <ToastContainer />
                <div className="popup_header d-f-c">
                    <div className="btn-header d-f-c">
                        <button className='btn-edit d-f-c' onClick={() => setIsUpdate(true)}><span className="material-icons-sharp edit">edit</span></button>
                        <button className='btn-delete d-f-c' onClick={() => setIsHandleDeleteOpen(true)}><span className="material-icons-sharp delete">delete</span></button>
                    </div>
                    <h1 className='titlee'>Information sur le trajet</h1>
                    <span className="material-icons-sharp" onClick={() => setIsOpenDetail(false)}>close</span>
                </div>
                {isUpdate && <form onSubmit={handleSubmit}>
                    <div className="div-form">
                        <div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="id">Id</label>
                                <input className='' type="text" disabled value={formData.id} name="id" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''}>Adresse de départ</label><br />
                                <input name="departurePoint"  type="text" value={formData.departurePoint} onChange={handleChange} />
                            </div><br />

                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''}>Adresse d'arrivé</label><br />
                                <input name="arrivalPoint"  type="text" value={formData.arrivalPoint} onChange={handleChange} />
                            </div><br />

                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="departureDate">Date</label>
                                <input type="date" value={formData.departureDate.split('T')[0]} name="departureDate" onChange={handleChange} />
                            </div>
                        </div>
                        <div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="price">Prix</label>
                                <input type="number" min={1} value={formData.price} name="price" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="seatAvailable">Place disponible</label>
                                <input type="number" min={1} value={formData.seatAvailable} name="seatAvailable" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="seatReserved">Place reservé</label>
                                <input type="number" min={0} value={formData.seatReserved} name="seatReserved" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="meetingPoint">Point de rancontre</label>
                                <input type="text" value={formData.meetingPoint || ' '} name="meetingPoint" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="destinationPoint">Point d'arrivé</label>*
                                <input type="text" value={formData.destinationPoint || ' '} name="destinationPoint" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="driverNote">Note du chauffeur</label>
                                <input type="number" value={formData.driverNote || 0} name="driverNote" onChange={handleChange} />
                            </div>
                            <div id="float-label">
                                <label className={isFloating ? 'Active' : ''} htmlFor="conductor.name">Conducteur</label>
                                <input type="text" value={formData.conductor?.name} name="conductor.name" onChange={handleChange} />
                            </div>
                        </div>

                    </div>
                    <div className='div-btn-submit d-f-c'>
                        <button className="btn btn-annuler" onClick={() => setIsUpdate(false)} >Annuler</button>
                        <button className="btn" type="submit" >Enregistrer</button>
                    </div>
                </form >
                }
                <div className="profile_setting__bloc">
                    <div className="profile_setting__bloc_righ">
                        <div className="profile_setting">
                            <h1>Détails du trajet</h1>
                            <div className="row">
                                <div className="label">Id</div>
                                <div className="value">{trajetSelected.id}</div>
                            </div>
                            <div className="row">
                                <div className="label">Ville de départ</div>
                                <div className="value">{trajetSelected.departurePoint}</div>
                            </div>
                            <div className="row">
                                <div className="label">Ville d'arrivé</div>
                                <div className="value">{trajetSelected.arrivalPoint}</div>
                            </div>
                            <div className="row">
                                <div className="label">Prix</div>
                                <div className="value">{trajetSelected.price}$</div>
                            </div>
                            <div className="row">
                                <div className="label">Place disponible</div>
                                <div className="value">{trajetSelected.seatAvailable}</div>
                            </div>
                            <div className="row">
                                <div className="label">Place reservées</div>
                                <div className="value">{trajetSelected.seatReserved}</div>
                            </div>
                            <div className="row">
                                <div className="label">Date et heure</div>
                                <div className="value">{trajetSelected.departureDate?.split('T')[0] + ' à ' + trajetSelected.departureDate?.split('T')[1]}</div>
                            </div>
                            <div className="row">
                                <div className="label">Point de rancontre</div>
                                <div className="value">{trajetSelected.meetingPoint || "-"}</div>
                            </div>
                            <div className="row">
                                <div className="label">Point de destination</div>
                                <div className="value">{trajetSelected.destinationPoint || "-"}</div>
                            </div>
                            <div className="row">
                                <div className="label">Notes du conducteur</div>
                                <div className="value">{trajetSelected.driverNote || "-"}</div>
                            </div>
                            <div className="row">
                                <div className="label">Conducteur</div>
                                <div className="value">{trajetSelected.conductor.name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="profile_setting__blocLeft">
                        <div>
                            <div className="profile_setting__image">
                                {image ?
                                    <img style={{ borderRadius: "20px" }} src={image} alt="" />
                                    :
                                    <h3>Chargement ...</h3>
                                }
                                <h1>{trajetSelected.arrivalPoint}</h1>
                            </div>
                            <br />
                        </div>
                        <div className="list_passager">
                            <h1>Liste des passager ayant reservés ce trajet</h1>
                            <ul>
                                {Array.isArray(userResevation) && userResevation?.map((name, index) => {
                                    return <li key={index}>{name}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div >

            {
                isHandleDeleteOpen &&
                <PopupConfirm
                    title='Vous êtes sur le point de supprimer définitivement ce trajet'
                    action={confirmDelete}
                />
            }
        </>


    )
}

export default TrajetDetail 