import axios from 'axios';
import { api_route } from '../../pages/utils/api_routes';
import { useState } from 'react';

export const GET_CONDUCTOR = "GET_CONDUCTOR";
// export const PUT_USERS_Certif = "PUT_USERS_Certif";
// export const PUT_USERS_Blocked = "PUT_USERS_Blocked";

export const getConductor = () => {
    return async (dispatch) => {
        return await axios.get(api_route?.trajet.url + 'readAllConductor')
            .then((res) => {
                dispatch({ type: GET_CONDUCTOR, payload: res.data })
            });
    }
}

// export const  putUser = ( endpoint, idUser, value) => {
//     return async (dispatch) => {
//         return await axios.put(api_route.users.url + endpoint +'/' + idUser + '/' + value)
//             .then((res) => {
//                 if (res.status === 200) {
//                     if (endpoint === 'userCertified') {
//                         dispatch({ type: PUT_USERS_Certif, payload: {idUser,value}  })
//                     }
//                     if (endpoint === 'userBlocked') {
//                         dispatch({ type: PUT_USERS_Blocked, payload: {idUser,value}  })
//                     }
//                 }
//             })
//             .catch(err =>{
//                 console.log('XXXXXX Erreur de modification de la certification : '+ err);
//             })
//     }
// }
 