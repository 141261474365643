import '../../assets/css/PopupDelete.css'

const PopupConfirm = ({ title, action }) => {
    return (
        <div className="div-delete">
            <b>{title}</b>
            <div className='div-btn-submit d-f-c'>
                <button className="btn btn-annuler" onClick={() => action(false)} >Annuler</button>
                <button className="btn" onClick={() => action(true)} >Oui</button>
            </div>
        </div>
    )
}


export default PopupConfirm