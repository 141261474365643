import axios from 'axios';
import { api_route } from '../../../pages/utils/api_routes';
import { useState } from 'react';
import { showToastError, showToastSuccèss } from '../../../pages/utils/toast';


export const GET_TRAJET_PRICE = "GET_TRAJET_PRICE";
export const UPDATE_TRAJET_PRICE = "UPDATE_TRAJET_PRICE";

export const getTrajetPrice = (id=1) => {
    return async (dispatch) => {
        return await axios.get(api_route?.projet.url + 'getReservationPrice/' + id)
            .then((res) => {
                dispatch({ type: GET_TRAJET_PRICE, payload: res.data })
            });
    }
}


export const updateTrajetPrice = (id, price) => {
    return async (dispatch) => {
        return await axios.put(api_route?.projet.url + 'setReservationPrice/' + id + '/' + price)
            .then((res) => {
                dispatch({ type: UPDATE_TRAJET_PRICE, payload: { id, price } })
                showToastSuccèss("Prix modifié avec succèss")
            })
            .catch(err => showToastError("Erreur de modification de ce prix ", err))
    }
}
