import config from './config.json'

let url_api = config.apiUrl

export const api_route = {
    users: {
        method: "GET",
        url: url_api + "USER-SERVICE/api/"
    },
    reservation: {
        method: "GET",
        url: url_api + "RESERVATION-SERVICE/api/"
    },
    annonces:{
      method: "GET",
      url: url_api + "ANNOUNCE-SERVICE/api/"
    },
    trajet:{
      method: "GET",
      url: url_api + "ITINERARY-SERVICE/api/"
    },
    projet:{
      method: "GET",
      url: url_api + "PROJECT-SERVICE/api/"
    },
    notification:{
      method: "GET",
      url: url_api + "NOTIFICATION-INAPP-SERVICE/api/"
    }
}