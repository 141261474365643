import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './store/reducers'
import { getUser } from './store/actions/user.action';
import { getAnnonce } from './store/actions/annonce.action';
import { getTrajet } from './store/actions/trajet.action';
import { getReservation } from './store/actions/reservation.action';
import { getConductor } from './store/actions/conductor.action';
import { getProjet} from './store/actions/projet/projet.action';
import { getTrajetPrice } from './store/actions/projet/projetReservPrice.action';
import { getProjetVersion } from './store/actions/projet/projetVersion.action';

const store = configureStore({
  reducer: rootReducer,
  devTools:true
})

store.dispatch(getUser())
store.dispatch(getAnnonce())
store.dispatch(getTrajet())
store.dispatch(getReservation())
store.dispatch(getConductor())
store.dispatch(getProjet())
store.dispatch(getTrajetPrice())
store.dispatch(getProjetVersion())


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

// const themeToggler = document.querySelector(".theme-toggler");


// // Changer de thème

// themeToggler?.addEventListener('click', () => {
//     document.body.classList.toggle('dark-theme-variables');

//     themeToggler.querySelector('span:nth-child(1)').classList.toggle('active');
//     themeToggler.querySelector('span:nth-child(2)').classList.toggle('active');

// })

reportWebVitals();

