import axios from 'axios';
import { api_route } from '../../pages/utils/api_routes';
import { useState } from 'react';
import { showToastError, showToastSuccèss } from '../../pages/utils/toast';

export const GET_USERS = "GET_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const PUT_USERS_Certif = "PUT_USERS_Certif";
export const PUT_USERS_Blocked = "PUT_USERS_Blocked";

export const getUser = () => {
    return async (dispatch) => {
        return await axios.get(api_route?.users.url + 'user')
            .then((res) => {
                dispatch({ type: GET_USERS, payload: res.data })
            });
    }
}

export const  putUser = ( endpoint, idUser, value) => {
    return async (dispatch) => {
        return await axios.put(api_route.users.url + endpoint +'/' + idUser + '/' + value)
            .then((res) => {
                if (res.status === 200) {
                    if (endpoint === 'userCertified') {
                        dispatch({ type: PUT_USERS_Certif, payload: {idUser,value}  })
                    }
                    if (endpoint === 'userBlocked') {
                        dispatch({ type: PUT_USERS_Blocked, payload: {idUser,value}  })
                    }
                }
            })
            .catch(err =>{
                console.log('Erreur de modification de la certification : '+ err);
            })
    }
}
 

export const deleteUser = (id) => {
    return async (dispatch) => {
        return await axios.delete(api_route?.users.url + 'user/' + id)
            .then((res) => {
                dispatch({ type: DELETE_USERS, payload: id })
                showToastSuccèss('Cet utilisateur à été supprimé avec succès')
            })
            .catch(err => {
                showToastError("Erreur de suppression de cet utilisateur")
            }
            )
    }
}