import '../../assets/css/Table.css'
import { useState } from 'react';
import Popup from '../components/Popup';
import { useLocation } from 'react-router-dom';
import ReservationDetail from './ReservationDetail';
import { isEmpty } from '../utils/isEmpty';


const Table = ({ reservation, titleTable }) => {

    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [reservationSelected, setReservationSelected] = useState({})
    const location = useLocation();

    const goToDetailReservation = (id) => {
        let reserv = reservation.filter(elt => elt.id === id)[0];
        setIsOpenDetail(true);
        setReservationSelected(reserv);
        // navigate('userDetail')
    }


    return (
        <>
            <div className="recent-oders">
                <h2>{titleTable} ({reservation?.length})</h2>
                <div className="container-table"
                    style={location.pathname === '/' ? { paddingBottom: '0px', height: '54vh' } : {}}
                >
                    <table>
                        <thead>
                            <tr>
                                <th>Ville de départ</th>
                                <th>Ville d'arrivé</th>
                                <th>Passager</th>
                                <th>Places reservées</th>
                                <th className='td_display_none'>Montant</th>
                                <th className='td_display_none'>Date et heure</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isEmpty(reservation) && reservation?.sort((a, b) => b.id - a.id).map((elt, index) =>
                                <tr key={index} onClick={() => goToDetailReservation(elt.id)}>
                                    <td>{elt.itinerary.departurePoint}</td>
                                    <td>{elt.itinerary.arrivalPoint}</td>
                                    <td>{elt.passenger.name}</td>
                                    <td>{elt.seatsNumber}</td>
                                    <td className='td_display_none'>${(elt.price * elt.seatsNumber).toFixed(2)}</td>
                                    <td className='td_display_none'>{elt.reservationDate?.split('T')[0]} à {elt.reservationDate?.split('T')[1]}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
                <a href="#"> Voir tout </a>

            </div>

            {isOpenDetail &&
                <Popup
                    itemSelected={reservationSelected}
                    setIsOpenDetail={setIsOpenDetail}
                    Compnent={ReservationDetail}
                />
            }
        </>
    )
}

export default Table
