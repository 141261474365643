import '../../assets/css/Table.css'
import userIcon from '../../assets/img/user.jpg'
import { useState } from 'react';
import Popup from '../components/Popup';
import { useLocation } from 'react-router-dom';
import UserDetail from '../Users/UserDetail';
import { useSelector } from 'react-redux';
// import axios from 'axios';
// import UserDetail from './UserDetail';
// import { useNavigate } from 'react-router-dom';
// import { api_route } from '../utils/api_routes';



const Table = ({ isTableUserDashboar, titleTable, users }) => {
    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [userSelected, setUserSelected] = useState({})
    const location = useLocation();

    const goToDetailUser = (id) => {
        let user = users.filter(elt => elt.id === id)[0];
        setIsOpenDetail(true);
        setUserSelected(user);
        // navigate('userDetail')
    }

    // console.log(location.pathname);

    return (
        <>
            <div className="recent-oders">
                <h2>{titleTable} ({users?.length})</h2>
                <div className="container-table"
                    style={isTableUserDashboar || location.pathname === '/' ? { paddingBottom: '0px', height: '54vh' } : {}}
                >
                    <table>
                        <thead>
                            <tr>
                                <th>Profil</th>
                                <th>Nom</th>
                                <th>Email</th>
                                <th className='td_display_none'>Téléphone</th>
                                <th className='td_display_none'>Certifié</th>
                                {!isTableUserDashboar ?
                                    <>
                                        <th className='td_display_none'>Adresse</th>
                                    </> : <></>
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {users?.length > 0 ? [...users].sort((a, b) => b.id - a.id).map((elt, index) =>
                                <tr key={index} onClick={() => goToDetailUser(elt.id)}>
                                    {elt.profilePicture === null ?
                                        <td className='img-icon'><img src={userIcon} alt="profil" /></td>
                                        :
                                        <td className='img'><img src={elt.profilePicture} alt="profil" /></td>
                                    }
                                    <td>{elt.name}</td>
                                    <td>{elt.email}</td>
                                    <td className='td_display_none'>{elt.phone || '-'}</td>
                                    {elt.certified === true ?
                                        <td className='td_display_none'> <span style={{ background: "green", color: "white" }}>Oui</span></td>
                                        :
                                        <td className='td_display_none'><span style={{ background: "red", color: "white" }}> Non</span> </td>}
                                    {!isTableUserDashboar ?
                                        <>
                                            <td className='td_display_none'>{elt.completeAdresse || '-'}</td>
                                        </> : <></>
                                    }
                                </tr>
                            ) : []}

                        </tbody>
                    </table>
                </div>
                <a href="#"> Voir tout </a>

            </div >
            {/* <Table 
                data={users}
                columns={columns}
                // defaultSort={["Nom"]}
                // showPagination={true}
                // showSortable={true}
            /> */}

            {isOpenDetail ?
                <Popup itemSelected={userSelected}
                    setIsOpenDetail={setIsOpenDetail}
                    Compnent={UserDetail}
                /> : <></>}
        </>
    )
}

export default Table
