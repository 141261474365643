import '../../assets/css/Popup.css'
import UserDetail from '../Users/UserDetail'

const Popup = ({ itemSelected, setIsOpenDetail, Compnent }) => {
    return (
        <div className='PopupBackground'>
            <div className="container-popup">
                < Compnent
                    itemSelected={itemSelected}
                    setIsOpenDetail={setIsOpenDetail}
                />
            </div>
        </div>
    )
}

export default Popup